import { CategoryEnum, RewardEnum } from '@/models/Rewards';
import { BaseFilters } from '@/models/Service';
import { verifyImageExists } from '@/utils/VerifyImageFile';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getChallengeActivity } from './activityElement/challengeServices';
import { getDndOrdenationActivity } from './activityElement/dndOrdenationActivityServices';
import { getDndPositionalActivity } from './activityElement/dndPositionalActivityServices';
import { getQuizActivity } from './activityElement/quizServices';
import { getTextActivity } from './activityElement/textActivityServices';
import { getTrueOrFalseActivity } from './activityElement/trueOrFalseActivityServices';
import { getVideoActivity } from './activityElement/videoActivityService';
import { getMe } from './authService';
import { getBookProgress } from './bookProgressServices';
import { getBook } from './bookServices';
import { GetChapter, getChapter } from './chapterServices';
import { ListCourseBaseFilters, listCoursesBases } from './courseServices';
import { ListEnrollmentsFilters, listEnrollments } from './enrollmentServices';
import { listLessonComments } from './lessonCommentServices';
import {
  LessonFilter,
  LessonSlugFilter,
  getLesson,
  listLessons,
  listLessonsByCourseSlug,
  listLessonsMeta,
} from './lessonServices';
import { listScheduledDays } from './scheduledDayServices';
import { ListToolsFilters, listTools } from './toolServices';
import { ListUnitsFilters, getUnit, listUnits } from './unitServices';
import { getUserProfile } from './userProfileServices';

import {
  ListAbsenceNotificationParams,
  listAbsenceNotification,
} from './absenceNotificationServices';
import {
  GetQuizProgressParams,
  getQuizProgress,
} from './activityElement/quizProgressServices';
import {
  GetTextActivityProgressParams,
  getTextActivityProgress,
} from './activityElement/textActivityProgressServices';
import {
  GetTrueOrFalseProgressParams,
  getTrueOrFalseProgress,
} from './activityElement/trueOrFalseProgressServices';
import {
  GetVideoActivityProgressParams,
  getVideoActivityProgress,
} from './activityElement/videoActivityProgressServices';
import {
  GetActivityProgressAttemptParams,
  getActivityProgressAttempt,
} from './activityProgressAttemptServices';
import {
  ActivityProgressFilterParams,
  getActivityProgress,
  listActivityProgress,
  listActivityProgressShortView,
} from './activityProgressServices';
import {
  ActivityFilter,
  getActivity,
  getActivityAnswers,
  listActivities,
  listActivitiesMeta,
} from './activityServices';
import {
  AvatarFilters,
  ListShopItemsFilters,
  getInventoryByAvatarId,
  getUserAvatarByUserId,
  listAvatars,
  listShopItems,
} from './avatarServices';
import {
  ListCoursePathFilters,
  getCoursePath,
  listCoursesPath,
} from './coursePathServices';
import {
  ListHomeworkActivities,
  getHomeworkActivity,
  listHomeworkActivities,
} from './homeworkActivityServices';
import {
  GetHomeworkProgress,
  HomeworkProgressFilters,
  getHomeworkProgress,
  listHomeworkProgresses,
} from './homeworkProgressServices';
import {
  ListKlassAssesments,
  listKlassAssessments,
} from './klassAssessmentServices';
import {
  ListKlassesFilters,
  getKlass,
  getKlassGroup,
  getKlassProgressById,
  listKlasses,
} from './klassServices';
import {
  ListLessonRatingParams,
  getAverageStudentLessonRating,
  listStudentLessonRating,
  listTeacherLessonRating,
} from './lessonRatingServices';
import {
  getOccurrence,
  ListOccurrences,
  listOccurrences,
} from './occurrenceServices';
import {
  ListPedagogicalSheet,
  listPedagogicalSheets,
} from './pedagogicalSheetServices';
import {
  GetProjectDataBody,
  ProjectFilters,
  getProject,
  getProjectData,
  listProjects,
} from './projectServices';
import {
  ScheduledLessonReportFiltersParams,
  listScheduledLessonReports,
} from './scheduledLessonReportServices';
import {
  ReschedulePreviewOptions,
  ScheduledLessonFilters,
  getNextLesson,
  getRescheduledLessonsPreview,
  getScheduledLesson,
  listScheduledLessons,
} from './scheduledLessonsServices';
import {
  ListCourseStatisticsFilters,
  ListPendingHomeworkFilters,
  ScheduledLessonStatisticsFilters,
  TeacherReportFilters,
  UnitStatisticsFilters,
  UserStatisticsParams,
  getAccessWeekByUserTypeCount,
  getPendingHomeworksCount,
  getScheduledLessonsStatistics,
  getTeacherReportStatistics,
  getTeacherReportStatisticsCount,
  getTeacherStudentsCount,
  getTotalCourses,
  getTotalUnits,
  getUnitsStatistics,
  getUsersByUserTypeCount,
  listCourseStatistics,
  listPendingHomeworks,
} from './statisticsServices';
import {
  ListStudentAssessments,
  getStudentAssessment,
  listStudentAssessments,
} from './studentAssessmentsServices';
import {
  CourseProgressFilters,
  getSimplifiedCourseProgress,
  listCourseProgresses,
  listNextSteps,
  listSimplifiedCourseProgressByUserId,
} from './courseProgressServices';
import {
  getRewardsByCategory,
  getRewardsByStudentId,
  listRewardTypeValues,
} from './transactionServices';
import {
  ListUsersFilters,
  getUser,
  listContacts,
  listStudents,
  listTeachers,
  listUser,
} from './userServices';

import {
  listLessonProgressBySlugCourse,
  LessonProgressFilters,
  listLessonProgress,
} from './lessonProgressServices';
import {
  ListStudentHistoryFilters,
  getStudentHistory,
} from './studentHistoryServices';

import {
  DndOrdenationActivityParams,
  getDndOrdenationActivityProgress,
} from './activityElement/dndOrdenationActivityProgressServices';

import {
  ListAreaFilter,
  getArea,
  listAreaParents,
  listAreas,
} from './areaServices';
import {
  GetDndPositionActivityProgressParams,
  getDndPositionalActivityProgress,
} from './dndActivityProgressServices';
import { getTag, ListTagFilter, listTags } from './tagServices';

import { ListAlertsFilters, getAlert, listAlerts } from './alertServices';

import {
  ListVacationsFilters,
  getVacationNotice,
  listVacationNotices,
} from './vacationNoticeServices';
import {
  listResources,
  ListResourceFilters,
  getResource,
  getResourceFile,
  listLessonResources,
} from './resourceServices';
import {
  CodeEditorProgressParam,
  getCodeEditorProgress,
} from './activityElement/codeEditorActivityProgressServices';
import { getCodeEditorActivity } from './activityElement/codeEditorActivityServices';
import {
  listStudentTagScore,
  ListStudentTagScoreParams,
} from './studentTagScoreServices';
import {
  getResourceProgress,
  listresourceProgress,
  ListResourceProgressFilters,
} from './resourceProgressServices';
import { HackathonFilters, listHackathons } from './hackathonServices';
import {
  HackathonStagesFilters,
  listHackathonStages,
} from './hackathonStagesServices';
import { FlagFilters, listFlags } from './flagServices';

export const absenceNotificationsQueryKeys = createQueryKeys(
  'absenceNotifications',
  {
    list: (filters: ListAbsenceNotificationParams = {}) => ({
      queryFn: ({ signal }) => listAbsenceNotification(filters, signal),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryKey: null,
          queryFn: ctx =>
            listAbsenceNotification(
              { ...filters, pageNumber: ctx.pageParam },
              ctx.signal,
            ),
        },
      },
    }),
  },
);

export const accessCountQueryKeys = createQueryKeys('accessCount', {
  list: (filters: UserStatisticsParams = {}) => ({
    queryFn: () => getAccessWeekByUserTypeCount(filters),
    queryKey: [{ filters }],
  }),
});

export const activitiesMetaQueryKeys = createQueryKeys('activitiesMeta', {
  list: (filters: ActivityFilter = {}) => ({
    queryKey: [{ filters }],
    queryFn: () => listActivitiesMeta(filters),
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listActivities({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const activitiesQueryKeys = createQueryKeys('activities', {
  get: (id: number) => ({
    queryFn: () => getActivity(id),
    queryKey: [id],
  }),
  list: (filters: ActivityFilter = {}) => ({
    queryKey: [{ filters }],
    queryFn: () => listActivities(filters),
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listActivities({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const activityProgressAttemptsQueryKeys = createQueryKeys(
  'activityProgressAttempts',
  {
    get: (params: GetActivityProgressAttemptParams) => ({
      queryKey: [params],
      queryFn: () => getActivityProgressAttempt(params),
    }),
  },
);

export const activityProgressesQueryKeys = createQueryKeys(
  'activitiesProgresses',
  {
    get: (id: number) => ({
      queryFn: () => getActivityProgress(id),
      queryKey: [id],
    }),
    list: (filters: ActivityProgressFilterParams = {}) => ({
      queryFn: () => listActivityProgress(filters),
      queryKey: [{ filters }],
      contextQueries: {
        inifinity: {
          queryFn: ctx =>
            listActivityProgress({ ...filters, pageNumber: ctx.pageParam }),
        },
      },
    }),
  },
);
export const areasQueryKeys = createQueryKeys('areas', {
  get: (id: number) => ({
    queryFn: () => getArea(id),
    queryKey: [id],
  }),

  list: (filters: ListAreaFilter = {}) => ({
    queryFn: () => listAreas(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listAreas({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
  listParents: (id: number) => ({
    queryFn: () => listAreaParents(id),
    queryKey: [id],
  }),
});

export const avatarInventoriesQueryKeys = createQueryKeys('avatarInventories', {
  get: (avatarId: number) => ({
    queryFn: () => getInventoryByAvatarId(avatarId),
    queryKey: [avatarId],
  }),
});

export const avatarsQueryKeys = createQueryKeys('avatars', {
  get: (userId: number) => ({
    queryFn: () => getUserAvatarByUserId(userId),
    queryKey: [userId],
  }),
  list: (filters: AvatarFilters = {}) => ({
    queryFn: () => listAvatars(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listAvatars({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const bookProgressesQueryKeys = createQueryKeys('bookProgresses', {
  get: (id: number) => ({ queryFn: () => getBookProgress(id), queryKey: [id] }),
});

export const booksQueryKeys = createQueryKeys('books', {
  get: (id: number) => ({
    queryFn: () => getBook(id),
    queryKey: [id],
  }),
});

export const categoryRewardsQueryKeys = createQueryKeys('categoryRewards', {
  get: (category: CategoryEnum) => ({
    queryFn: () => getRewardsByCategory(category),
    queryKey: [category],
  }),
});

export const challengesQueryKeys = createQueryKeys('challenges', {
  get: (lessonId: number) => ({
    queryKey: [lessonId],
    queryFn: () => getChallengeActivity(lessonId),
  }),
});

export const chaptersQueryKeys = createQueryKeys('chapters', {
  get: (params: GetChapter) => ({
    queryFn: () => getChapter(params),
    queryKey: [params],
  }),
});

export const codeEditorActivitiesQueryKeys = createQueryKeys(
  'codeEditorActivity',
  {
    get: (id: number) => ({
      queryFn: () => getCodeEditorActivity(id),
      queryKey: [id],
    }),
  },
);

export const codeEditorProgressQueryKeys = createQueryKeys(
  'codeEditorActivityProgress',
  {
    get: (params: CodeEditorProgressParam) => ({
      queryFn: () => getCodeEditorProgress(params),
      queryKey: [params],
    }),
  },
);

export const correctAnswersQueryKeys = createQueryKeys(
  'activityCorrectAnswers',
  {
    get: (id: number) => ({
      queryFn: () => getActivityAnswers(id),
      queryKey: [id],
    }),
  },
);

export const courseBasesQueryKeys = createQueryKeys('courseBases', {
  list: (filters: ListCourseBaseFilters = {}) => ({
    queryFn: () => listCoursesBases(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listCoursesBases({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const coursePathsQueryKeys = createQueryKeys('coursePaths', {
  get: (slug: string) => ({
    queryFn: () => getCoursePath(slug),
    queryKey: [slug],
  }),
  list: (filters: ListCoursePathFilters = {}) => ({
    queryFn: () => listCoursesPath(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listCoursesPath({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const courseProgressesQueryKeys = createQueryKeys('courseProgresses', {
  list: (filters: CourseProgressFilters = {}) => ({
    queryFn: () => listCourseProgresses(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listCourseProgresses({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const courseStatisticsQueryKeys = createQueryKeys('courseStatistics', {
  list: (filters: ListCourseStatisticsFilters = {}) => ({
    queryFn: () => listCourseStatistics(filters),
    queryKey: [{ filters }],
    contextQueries: {
      totalSum: {
        queryFn: () => getTotalCourses(),
        queryKey: null,
      },
    },
  }),
});

export const dndOrdenationActivitiesProgressesQueryKeys = createQueryKeys(
  'dndOrdenationActivitiesProgresses',
  {
    get: (params: DndOrdenationActivityParams) => ({
      queryKey: [params],
      queryFn: () => getDndOrdenationActivityProgress(params),
    }),
  },
);

export const dndOrdenationActivitiesQueryKeys = createQueryKeys(
  'dndOrdenationActivities',
  {
    get: (dndActivityId: number) => ({
      queryFn: () => getDndOrdenationActivity(dndActivityId),
      queryKey: [dndActivityId],
    }),
  },
);

export const dndPositionalActivitiesProgressesQueryKeys = createQueryKeys(
  'dndPositionalActivitiesProgresses',
  {
    get: (param: GetDndPositionActivityProgressParams) => ({
      queryFn: () => getDndPositionalActivityProgress(param),
      queryKey: [param],
    }),
  },
);

export const dndPositionalActivitiesQueryKeys = createQueryKeys(
  'dndPositionalActivities',
  {
    get: (dndActivityId: number) => ({
      queryFn: () => getDndPositionalActivity(dndActivityId),
      queryKey: [dndActivityId],
    }),
  },
);

export const enrollmentsQueryKeys = createQueryKeys('enrollments', {
  list: (filters: ListEnrollmentsFilters = {}) => ({
    queryFn: () => listEnrollments(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listEnrollments({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const flagsQueryKeys = createQueryKeys('flags', {
  list: (filters: FlagFilters = {}) => ({
    queryFn: () => listFlags(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listFlags({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});
export const hackathonsQueryKeys = createQueryKeys('hackathons', {
  list: (filters: HackathonFilters = {}) => ({
    queryFn: () => listHackathons(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listHackathons({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const hackathonsStagesQueryKeys = createQueryKeys('hackathonsStages', {
  list: (filters: HackathonStagesFilters = {}) => ({
    queryFn: () => listHackathonStages(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listHackathonStages({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const homeworkActivitiesQueryKeys = createQueryKeys(
  'homeworkActivities',
  {
    get: (id: number) => ({
      queryFn: () => getHomeworkActivity(id),
      queryKey: [id],
    }),
    list: (filters: ListHomeworkActivities = {}) => ({
      queryFn: () => listHomeworkActivities(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryKey: null,
          queryFn: ctx =>
            listHomeworkActivities({ ...filters, pageNumber: ctx.pageParam }),
        },
      },
    }),
  },
);

export const homeworkProgressQueryKeys = createQueryKeys('homeworkProgresses', {
  get: (params: GetHomeworkProgress) => ({
    queryFn: () => getHomeworkProgress(params),
    queryKey: [params],
  }),
  list: (homeworkId: number, filters: HomeworkProgressFilters = {}) => ({
    queryFn: () => listHomeworkProgresses(homeworkId, filters),
    queryKey: [{ homeworkId, filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listHomeworkProgresses(homeworkId, {
            ...filters,
            pageNumber: ctx.pageParam,
          }),
      },
    },
  }),
});

export const imagesQueryKeys = createQueryKeys('images', {
  get: (url: string, timeout?: number) => ({
    queryFn: () => verifyImageExists(url, timeout),
    queryKey: [url],
  }),
});

export const klassAssessmentsQueryKeys = createQueryKeys('klassAssessments', {
  list: (filters: ListKlassAssesments = {}) => ({
    queryFn: () => listKlassAssessments(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listKlassAssessments({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const klassesProgressesQueryKeys = createQueryKeys('klassesProgress', {
  get: (klassId: number) => ({
    queryFn: () => getKlassProgressById(klassId),
    queryKey: [klassId],
  }),
});

export const klassesQueryKeys = createQueryKeys('klasses', {
  get: (id: number) => ({
    queryFn: () => getKlass(id),
    queryKey: [id],
  }),
  group: (klassId: number) => ({
    queryFn: () => getKlassGroup(klassId),
    queryKey: [klassId],
  }),
  list: (filters: ListKlassesFilters = {}) => ({
    queryFn: () => listKlasses(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listKlasses({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const lessonCommentsQueryKeys = createQueryKeys('lessonComments', {
  list: (lessonId: number, filters: BaseFilters = {}) => ({
    queryFn: () => listLessonComments(lessonId),
    queryKey: [{ lessonId, filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listLessonComments(lessonId, {
            ...filters,
            pageNumber: ctx.pageParam,
          }),
      },
    },
  }),
});

export const lessonsMetaQueryKeys = createQueryKeys('lessonsMeta', {
  list: (filters: LessonFilter = {}) => ({
    queryFn: () => listLessonsMeta(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listLessonsMeta({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const lessonsProgressesQueryKeys = createQueryKeys('lessonProgresses', {
  list: (filters: LessonProgressFilters = {}) => ({
    queryFn: () => listLessonProgress(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listLessonProgress({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),

  nestedList: (slug: string, filters: LessonProgressFilters = {}) => ({
    queryFn: () => listLessonProgressBySlugCourse(slug, filters),
    queryKey: [{ slug, filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listLessonProgressBySlugCourse(slug, {
            ...filters,
            pageNumber: ctx.pageParam,
          }),
        queryKey: null,
      },
    },
  }),
});

export const lessonsQueryKeys = createQueryKeys('lessons', {
  get: (id: number) => ({
    queryFn: () => getLesson(id),
    queryKey: [id],
  }),
  list: (filters: LessonFilter = {}) => ({
    queryFn: () => listLessons(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listLessons({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
  nestedList: (slug: string, filters: LessonSlugFilter = {}) => ({
    queryFn: () => listLessonsByCourseSlug(slug, filters),
    queryKey: [{ slug, filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listLessonsByCourseSlug(slug, {
            ...filters,
            pageNumber: ctx.pageParam,
          }),
        queryKey: null,
      },
    },
  }),
});

export const meQueryKeys = createQueryKeys('me', {
  get: (jwt: string) => ({
    queryFn: () => getMe(),
    queryKey: [jwt],
  }),
});

export const nextScheduledLessonsQueryKeys = createQueryKeys(
  'nextScheduledLessons',
  {
    get: (klassId: number) => ({
      queryFn: () => getNextLesson(klassId),
      queryKey: [klassId],
    }),
  },
);

export const nextStepsQueryKeys = createQueryKeys('nextSteps', {
  list: (courseProgressId: number) => ({
    queryFn: () => listNextSteps(courseProgressId),
    queryKey: [{ courseProgressId }],
  }),
});

export const alertQueryKeys = createQueryKeys('alerts', {
  list: (filters: ListAlertsFilters = {}) => ({
    queryFn: () => listAlerts(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listAlerts({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
  get: (id: number) => ({
    queryFn: () => getAlert(id),
    queryKey: [id],
  }),
});

export const occurrencesQueryKeys = createQueryKeys('occurrences', {
  list: (filters: ListOccurrences = {}) => ({
    queryFn: () => listOccurrences(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listOccurrences({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),

  get: (occurrenceId: number) => ({
    queryFn: () => getOccurrence(occurrenceId),
    queryKey: [occurrenceId],
  }),
});

export const pedagogicalSheetsQueryKeys = createQueryKeys('pedagogicalSheets', {
  list: (filters: ListPedagogicalSheet = {}) => ({
    queryFn: () => listPedagogicalSheets(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listPedagogicalSheets({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const pendingHomeworksQueryKeys = createQueryKeys('pendingHomeworks', {
  list: (filters: ListPendingHomeworkFilters = {}) => ({
    queryFn: () => listPendingHomeworks(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listPendingHomeworks({ ...filters, pageNumber: ctx.pageParam }),
      },
      totalSum: {
        queryFn: () => getPendingHomeworksCount(filters),
        queryKey: null,
      },
    },
  }),
});

export const pendingSummariesQueryKeys = createQueryKeys('pendingSummaries', {
  list: (filters: TeacherReportFilters = {}) => ({
    queryFn: () => getTeacherReportStatistics(filters),
    queryKey: [{ filters }],
    contextQueries: {
      totalSum: {
        queryFn: () => getTeacherReportStatisticsCount(filters),
        queryKey: null,
      },
      infinity: {
        queryFn: ctx =>
          getTeacherReportStatistics({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const projectsMetadaraQuerykeys = createQueryKeys('projectsMetadata', {
  get: (params: GetProjectDataBody) => ({
    queryFn: () => getProjectData(params),
    queryKey: [params],
  }),
});

export const projectsQuerykeys = createQueryKeys('projects', {
  get: (id: number) => ({
    queryFn: () => getProject(id),
    queryKey: [id],
  }),
  list: (filters: ProjectFilters = {}) => ({
    queryFn: () => listProjects(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listProjects({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const quizActivitiesQueryKeys = createQueryKeys('quizActivities', {
  get: (id: number) => ({
    queryKey: [id],
    queryFn: () => getQuizActivity(id),
  }),
});

export const quizActivityProgressesQueryKeys = createQueryKeys(
  'quizActivityProgresses',
  {
    get: (params: GetQuizProgressParams) => ({
      queryFn: () => getQuizProgress(params),
      queryKey: [params],
    }),
  },
);

export const reschedulePreviewQueryKeys = createQueryKeys('reschedulePreview', {
  get: (scheduledLessonId: number, options: ReschedulePreviewOptions) => ({
    queryFn: () => getRescheduledLessonsPreview(scheduledLessonId, options),
    queryKey: [{ scheduledLessonId, options }],
  }),
});

export const rewardsQueryKeys = createQueryKeys('studentRewards', {
  get: (studentId: number) => ({
    queryFn: () => getRewardsByStudentId(studentId),
    queryKey: [studentId],
  }),
});

export const rewardValuesQueryKeys = createQueryKeys('rewardsValues', {
  list: (reward: RewardEnum) => ({
    queryKey: [{ reward }],
    queryFn: () => listRewardTypeValues(reward),
  }),
});

export const scheduledDaysQueryKeys = createQueryKeys('scheduledDays', {
  list: () => ({
    queryKey: [''],
    queryFn: () => listScheduledDays(),
  }),
});

export const scheduledLessonReportsQueryKeys = createQueryKeys(
  'scheduledLessonReports',
  {
    list: (filters: ScheduledLessonReportFiltersParams = {}) => ({
      queryFn: () => listScheduledLessonReports(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryKey: null,
          queryFn: ctx =>
            listScheduledLessonReports({
              ...filters,
              pageNumber: ctx.pageParam,
            }),
        },
      },
    }),
  },
);

export const scheduledLessonsQueryKeys = createQueryKeys('scheduledLessons', {
  get: (id: number) => ({
    queryFn: () => getScheduledLesson(id),
    queryKey: [id],
  }),
  list: (filters: ScheduledLessonFilters = {}) => ({
    queryFn: () => listScheduledLessons(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listScheduledLessons({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const scheduledLessonStatisticsQueryKeys = createQueryKeys(
  'scheduledLessonStatistics',
  {
    list: (filters: ScheduledLessonStatisticsFilters = {}) => ({
      queryKey: [{ filters }],
      queryFn: () => getScheduledLessonsStatistics(filters),
    }),
  },
);

export const shopQueryKeys = createQueryKeys('shop', {
  list: (filters: ListShopItemsFilters = {}) => ({
    queryFn: () => listShopItems(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listShopItems({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const shortActivitiesProgressesQueryKeys = createQueryKeys(
  'shortActivitiesProgresses',
  {
    list: (filters: ActivityProgressFilterParams = {}) => ({
      queryFn: () => listActivityProgressShortView(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryFn: ctx =>
            listActivityProgressShortView({
              ...filters,
              pageNumber: ctx.pageParam,
            }),
          queryKey: null,
        },
      },
    }),
  },
);

export const simplifiedCourseProgressesQueryKeys = createQueryKeys(
  'simplifiedCourseProgresses',
  {
    get: (studentId: number, courseprogressId: number) => ({
      queryFn: () => getSimplifiedCourseProgress(studentId, courseprogressId),
      queryKey: [studentId, courseprogressId],
    }),
    list: (studentId: number, filters: CourseProgressFilters = {}) => ({
      queryKey: [{ filters, studentId }],
      queryFn: () => listSimplifiedCourseProgressByUserId(studentId, filters),
      contextQueries: {
        infinity: {
          queryFn: ctx =>
            listSimplifiedCourseProgressByUserId(studentId, {
              ...filters,
              pageNumber: ctx.pageParam,
            }),
          queryKey: null,
        },
      },
    }),
  },
);

export const studentAssessmentsQuerykeys = createQueryKeys(
  'studentAssessments',
  {
    get: (assessmentId: number) => ({
      queryFn: () => getStudentAssessment(assessmentId),
      queryKey: [assessmentId],
    }),
    list: (filters: ListStudentAssessments = {}) => ({
      queryFn: () => listStudentAssessments(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryFn: ctx =>
            listStudentAssessments({ ...filters, pageNumber: ctx.pageParam }),
          queryKey: null,
        },
      },
    }),
  },
);

export const studentHistoriesQueryKeys = createQueryKeys('studentHistories', {
  get: (studentId: number, params: ListStudentHistoryFilters = {}) => ({
    queryFn: () => getStudentHistory(studentId, params),
    queryKey: [{ studentId, params }],
  }),
});

export const studentLessonsRatingAvaragesQueryKeys = createQueryKeys(
  'studentLessonRatingAvarages',
  {
    get: (params: ListLessonRatingParams = {}) => ({
      queryFn: () => getAverageStudentLessonRating(params),
      queryKey: [params],
    }),
  },
);

export const studentLessonsRatingQueryKeys = createQueryKeys(
  'studentLessonRatings',
  {
    list: (filters: ListLessonRatingParams = {}) => ({
      queryFn: () => listStudentLessonRating(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryKey: null,
          queryFn: ctx =>
            listStudentLessonRating({ ...filters, pageNumber: ctx.pageParam }),
        },
      },
    }),
  },
);

export const studentTagScoreQueryKeys = createQueryKeys('studentTagScores', {
  list: (filters: ListStudentTagScoreParams = {}) => ({
    queryFn: () => listStudentTagScore(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listStudentTagScore({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const studentsQuerykeys = createQueryKeys('students', {
  list: (filters: ListUsersFilters = {}) => ({
    queryFn: () => listStudents(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listStudents({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const resourceQuerykeys = createQueryKeys('resource', {
  get: (resourceId: number) => ({
    queryFn: () => getResource(resourceId),
    queryKey: [resourceId],
  }),
  list: (filters: ListResourceFilters = {}) => ({
    queryFn: () => listResources(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listResources({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
  getFile: (url: string) => ({
    queryFn: () => getResourceFile(url),
    queryKey: [url],
  }),
  nestedList: (lessonId: number, filters: ListResourceFilters = {}) => ({
    queryFn: () => listLessonResources(lessonId, filters),
    queryKey: [{ lessonId, filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx =>
          listLessonResources(lessonId, {
            ...filters,
            pageNumber: ctx.pageParam,
          }),
        queryKey: null,
      },
    },
  }),
});

export const resourceProgressQuerykeys = createQueryKeys('resourceProgress', {
  get: (resourceProgressId: number) => ({
    queryFn: () => getResourceProgress(resourceProgressId),
    queryKey: [resourceProgressId],
  }),
  list: (filters: ListResourceProgressFilters = {}) => ({
    queryFn: () => listresourceProgress(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listresourceProgress({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const tagsQueryKeys = createQueryKeys('tags', {
  list: (filters: ListTagFilter = {}) => ({
    queryFn: () => listTags(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listTags({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
  get: (id: number) => ({
    queryFn: () => getTag(id),
    queryKey: [id],
  }),
});

export const teacherLessonRatingssQuerykeys = createQueryKeys(
  'teacherLessonRatings',
  {
    list: (filters: ListLessonRatingParams = {}) => ({
      queryFn: () => listTeacherLessonRating(filters),
      queryKey: [{ filters }],
      contextQueries: {
        infinity: {
          queryKey: null,
          queryFn: ctx =>
            listTeacherLessonRating({ ...filters, pageNumber: ctx.pageParam }),
        },
      },
    }),
  },
);

export const teachersQuerykeys = createQueryKeys('teachers', {
  list: (filters: ListUsersFilters = {}) => ({
    queryFn: () => listTeachers(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryFn: ctx => listTeachers({ ...filters, pageNumber: ctx.pageParam }),
        queryKey: null,
      },
    },
  }),
});

export const teacherStudentsCountQueryKeys = createQueryKeys(
  'teacherStudentsCount',
  {
    list: (filters: TeacherReportFilters = {}) => ({
      queryFn: () => getTeacherStudentsCount(filters),
      queryKey: [{ filters }],
    }),
  },
);

export const textActivitiesQueryKeys = createQueryKeys('textActivities', {
  get: (id: number) => ({
    queryKey: [id],
    queryFn: () => getTextActivity(id),
  }),
});

export const textActivityProgressesQueryKeys = createQueryKeys(
  'textActivityProgresses',
  {
    get: (params: GetTextActivityProgressParams) => ({
      queryKey: [params],
      queryFn: () => getTextActivityProgress(params),
    }),
  },
);

export const toolsQueryKeys = createQueryKeys('tools', {
  list: (filters: ListToolsFilters = {}) => ({
    queryKey: [{ filters }],
    queryFn: () => listTools(filters),
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listTools({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const trueOrFalseActivitiesQueryKeys = createQueryKeys(
  'trueOrFalseActivities',
  {
    get: (id: number) => ({
      queryKey: [id],
      queryFn: () => getTrueOrFalseActivity(id),
    }),
  },
);

export const trueOrFalseActivityProgressQueryKeys = createQueryKeys(
  'trueOrFalseActivitiesProgresses',
  {
    get: (params: GetTrueOrFalseProgressParams) => ({
      queryKey: [params],
      queryFn: () => getTrueOrFalseProgress(params),
    }),
  },
);

export const unitsQueryKeys = createQueryKeys('units', {
  get: (id: number) => ({
    queryFn: () => getUnit(id),
    queryKey: [id],
  }),
  list: (filters: ListUnitsFilters = {}) => ({
    queryFn: () => listUnits(filters),
    queryKey: [{ filters }],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listUnits({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const unitsStatisticsQueryKeys = createQueryKeys('unitStatistics', {
  list: (filters: UnitStatisticsFilters = {}) => ({
    queryFn: () => getUnitsStatistics(filters),
    queryKey: [{ filters }],
    contextQueries: {
      totalSum: {
        queryFn: () => getTotalUnits(filters),
        queryKey: null,
      },
    },
  }),
});

export const userProfilesQueryKeys = createQueryKeys('userProfiles', {
  get: (username: string) => ({
    queryFn: () => getUserProfile(username),
    queryKey: [username],
  }),
});

export const usersCountQueryKeys = createQueryKeys('userCount', {
  list: (filters: UserStatisticsParams = {}) => ({
    queryFn: () => getUsersByUserTypeCount(filters),
    queryKey: [{ filters }],
  }),
});

export const usersQueryKeys = createQueryKeys('users', {
  get: (id: number) => ({ queryKey: [id], queryFn: () => getUser(id) }),
  list: (filters: ListUsersFilters = {}) => ({
    queryFn: () => listUser(filters),
    queryKey: [filters],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx => listUser({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
  listContacts: (userId: number, filters: BaseFilters = {}) => ({
    queryFn: () => listContacts(userId, filters),
    queryKey: [userId, filters],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listContacts(userId, { ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const vacationNoticeQueryKeys = createQueryKeys('vacations', {
  get: (id: number) => ({
    queryKey: [id],
    queryFn: () => getVacationNotice(id),
  }),
  list: (filters: ListVacationsFilters = {}) => ({
    queryFn: () => listVacationNotices(filters),
    queryKey: [filters],
    contextQueries: {
      infinity: {
        queryKey: null,
        queryFn: ctx =>
          listVacationNotices({ ...filters, pageNumber: ctx.pageParam }),
      },
    },
  }),
});

export const videoActivitiesQueryKeys = createQueryKeys('videoActivities', {
  get: (id: number) => ({
    queryFn: () => getVideoActivity(id),
    queryKey: [id],
  }),
});

export const videoActivityProgressQueryKeys = createQueryKeys(
  'videoActivityProgresses',
  {
    get: (params: GetVideoActivityProgressParams) => ({
      queryFn: () => getVideoActivityProgress(params),
      queryKey: [params],
    }),
  },
);
